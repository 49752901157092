import { useEffect, useState } from "react";
import InformModal from "../common/modal/InformModal";
import PolicyModal from "../signup/PolicyModal";
import HomeCheckIcon from "../vector/home/HomeCheckIcon";
import { quickserveInquiry } from "../../services/PublicService";

const InquirySection = () => {
  const INQUIRY_OPTIONS = [
    "제품 광고",
    "기업/브랜드 홍보",
    "인터뷰",
    "기업 행사",
    "교육",
    "기타",
  ];

  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [isAgreePrivacyPolicy, setIsAgreePrivacyPolicy] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [manager, setManager] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [isInquiryValid, setIsInquiryValid] = useState(false);
  const [modal, setModal] = useState({ isOpen: false, phrase: "" });
  const [policyModal, setPolicyModal] = useState(false);

  useEffect(() => {
    setIsInquiryValid(isAgreePrivacyPolicy);
  }, [isAgreePrivacyPolicy]);

  const sendInquiry = async () => {
    if (!isInquiryValid) {
      setModal({ isOpen: true, phrase: "개인정보처리방침에 동의해주세요." });
      return;
    }
    try {
      await quickserveInquiry({
        inquiryTit: selectedOption,
        contactName: manager,
        reqEmail: email,
        phoneNum: mobile,
        resEmail: "henry.lim@saltlux.com",
        inquiryCtnt: videoLink
          ? `문의사항 : ${description} / 참조 비디오 : ${videoLink}`
          : `문의사항 : ${description}`,
      });
      setModal({
        isOpen: true,
        phrase: "문의가 성공적으로 접수되었습니다. 감사합니다.",
      });
    } catch (e) {
      console.log("e", e);
    }
  };

  return (
    <div className="w-full bg-black flex flex-col items-center justify-center mt-[100px] md:mt-[300px] text-white">
      <InformModal
        modal={modal.isOpen}
        closeModal={() => setModal((prev) => ({ ...prev, isOpen: false }))}
        phrase={modal.phrase}
      />
      <PolicyModal
        termsCode="M01F0"
        policyModal={policyModal}
        closeModal={() => setPolicyModal(false)}
      />
      <div className="flex flex-col items-center justify-center max-w-[900px] w-full mx-auto px-4">
        <div className="bg-gradient-to-r from-[#ff4e83] to-[#ffbb54] text-transparent bg-clip-text font-bold text-[18px] md:text-[24px] mb-4">
          영상 제작 문의
        </div>
        <div className="font-bold text-[26px] md:text-[44px] mb-[38px] whitespace-normal text-center">
          지금 시작해보세요.
        </div>
        <div className="px-4">
          <div className="text-[16px] md:text-[24px] text-center text-white/60 whitespace-normal">
            아직도 고민 중이신가요? 문의 한 번 해 보세요. 플루닛과 함께라면 더
            이상 시간과 비용을 낭비하지 않으셔도 됩니다.
          </div>
          <div className="text-[16px] md:text-[24px] text-center text-white/60 mb-[30px] whitespace-normal font-bold">
            당신의 영상, 오늘 바로 저희가 만들어 드립니다.
          </div>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <div className="relative">
            <div
              className="relative cursor-pointer"
              onClick={() => {
                setIsOptionOpen(!isOptionOpen);
              }}
            >
              <div className="w-full h-[66px] rounded-[10px] border border-white/20 bg-[#141414] text-white/60 px-6 md:px-10 py-5 appearance-none">
                {selectedOption || "영상 유형 선택"}
              </div>
              <div
                className={`absolute inset-y-0 right-4 md:right-10 flex items-center px-2 text-white transition-transform duration-300 ${
                  isOptionOpen ? "rotate-180" : ""
                }`}
              >
                <img src="/quickserve/arrow-down.png" alt="arrow-down" />
              </div>
            </div>
            <div
              className={`absolute w-full bottom-0 translate-y-[100%] flex flex-col px-10 py-6 gap-4 rounded-[10px] bg-[#141414] border border-[#ff6d51] transition-all duration-300 ease-in-out ${
                isOptionOpen ? "opacity-100 visible" : "opacity-0 invisible"
              }`}
            >
              {INQUIRY_OPTIONS.map((option, index) => {
                return (
                  <div
                    key={option}
                    className="text-[18px] font-bold text-white/60 cursor-pointer"
                    onClick={() => {
                      setIsOptionOpen(false);
                      setSelectedOption(option);
                    }}
                  >
                    {option}
                  </div>
                );
              })}
            </div>
          </div>
          <input
            className="w-full h-[66px] rounded-[10px] border border-white/20 bg-[#141414] text-white/60 px-6 md:px-10 py-5"
            placeholder="연락 받으실 담당자 성함"
            value={manager}
            onChange={(e) => setManager(e.target.value)}
          />
          <input
            className="w-full h-[66px] rounded-[10px] border border-white/20 bg-[#141414] text-white/60 px-6 md:px-10 py-5"
            placeholder="이메일"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="w-full h-[66px] rounded-[10px] border border-white/20 bg-[#141414] text-white/60 px-6 md:px-10 py-5"
            placeholder="휴대폰 번호"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <textarea
            className="w-full h-[278px] rounded-[10px] border border-white/20 bg-[#141414] text-white/60 px-6 md:px-10 py-5 resize-none"
            placeholder="의뢰하실 영상내용&#10;아래 예시를 참고하셔서 이 중 편하신 내용을 기재해주세요.&#10;- 고객님의 비즈니스 유형 : 관공서/일반기업/소상공인 등&#10;- 영상 장르 : 홍보,프로모션/IR/프레젠테이션 등&#10;- 내용 : 제작하시고자 하는 영상의 내용을 간단히 기재해주세요."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            className="w-full h-[66px] rounded-[10px] border border-white/20 bg-[#141414] text-white/60 px-6 md:px-10 py-5"
            placeholder="참고 영상"
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
          />
          <div className="flex flex-wrap break-keep text-[14px] text-[#FF6D51] w-full h-[100px] whitespace-normal">
            * 참고할만한 영상이나 원하시는 영상과 유사한 영상의 링크 주소를
            적어주시면 더 정확히 안내해드리겠습니다.
          </div>
        </div>
      </div>
      <div className="w-full bg-gradient-to-b from-white/0 to-white/20 h-[200px] md:h-[400px]">
        <div className="flex flex-col md:flex-row flex-1 items-start md:items-center justify-between max-w-[900px] mx-auto gap-[60px] px-[20px]">
          <div className="flex flex-row items-center gap-4">
            <i
              className="ml-3 cursor-pointer"
              onClick={() => setIsAgreePrivacyPolicy(!isAgreePrivacyPolicy)}
            >
              <HomeCheckIcon opacity={isAgreePrivacyPolicy ? "1" : "0.2"} />
            </i>
            <span className="flex flex-row">
              <u
                className="cursor-pointer"
                onClick={() => setPolicyModal(true)}
              >
                개인정보처리방침
              </u>
              에 동의합니다.
            </span>
          </div>
          <div
            className="flex items-center justify-center px-[28px] py-[16px] rounded-[8px] cursor-pointer bg-[#FF6D51] w-full md:w-auto"
            onClick={sendInquiry}
          >
            문의 보내기
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquirySection;
